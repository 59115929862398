type Period =
  | "janeiro"
  | "fevereiro"
  | "março"
  | "abril"
  | "maio"
  | "junho"
  | "julho"
  | "agosto"
  | "setembro"
  | "outubro"
  | "novembro"
  | "dezembro";

const allPeriods: Period[] = [
  "janeiro",
  "fevereiro",
  "março",
  "abril",
  "maio",
  "junho",
  "julho",
  "agosto",
  "setembro",
  "outubro",
  "novembro",
  "dezembro",
];

export interface AnnualBalance {
  year: number;
  periods: Period[];
}

export const annualBalances: AnnualBalance[] = [
  {
    year: 2024,
    periods: allPeriods.slice(0, 10), // janeiro - Março
    //periods: allPeriods,
  },
  {
    year: 2023,
    periods: allPeriods.slice(0, 12), // janeiro - junho
    //periods: allPeriods,
  },
  {
    year: 2022,
    //periods: allPeriods.slice(0, 11), // janeiro - novembro
    periods: allPeriods,
  },
  {
    year: 2021,
    //periods: allPeriods.slice(0, 11), // janeiro - novembro
    periods: allPeriods,
  },
  {
    year: 2020,
    periods: allPeriods,
  },
  {
    year: 2019,
    periods: allPeriods,
  },
  {
    year: 2018,
    periods: allPeriods,
  },
  {
    year: 2017,
    periods: allPeriods,
  },
];
